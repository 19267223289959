<!-- 招生系统基础配置 -- 中考考试科目管理 -- 列表 -->
<template>
	<div>
		<list-template :current-page="page" :loading="loading" :search-config="searchConfig" :table-config="tableConfig" :table-data="tableData" :total="total" has-add @onAdd="$router.push('./add')"
										@onChangePage="handleCurrentChange" @onHandle="handleEdit" @onReset='reset' @onSearch="onSearch"></list-template>

		<!-- 历史记录 -->
		<h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList" @close="historyDialogShow = false"></h-dialog>
	</div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {mapState} from "vuex";
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate},
	data() {
		return {
			loading: true,
			search: {province: '', city: '', area: ''},
			searchConfig: [
				{
					prop: "province",
					tag: 'select',
					placeholder: "筛选省份",
					label: 'address_name',
					value: 'address_code',
					options: [],
					change: (e, s) => {
						this.searchConfig[1].options = [];
						s.city = '';
						s.area = '';
						this.getCity(e)
					}
				},
				{
					prop: "city",
					tag: 'select',
					placeholder: "筛选城市",
					label: 'address_name',
					value: 'address_code',
					options: [],
					// change: (e, s) => {
					// 	// this.searchConfig[2].options = [];
					// 	// s.area = '';
					// 	// this.getArea(e)
					// }
				},
				{
					prop: "year",
					tag: 'select',
					placeholder: "筛选考试年份",
					options: [
						{label: (new Date().getFullYear() - 2).toString(), value: new Date().getFullYear() - 2},
						{label: (new Date().getFullYear() - 1).toString(), value: new Date().getFullYear() - 1},
						{label: new Date().getFullYear().toString(), value: new Date().getFullYear()},
						{label: (new Date().getFullYear() + 1).toString(), value: new Date().getFullYear() + 1},
						{label: (new Date().getFullYear() + 2).toString(), value: new Date().getFullYear() + 2},
					]
				},
			],
			tableData: [],
			//总条数
			total: 0,
			pageCount: 0,

			tableConfig: [
				{prop: "province", label: "省份"},
				{prop: "city", label: "城市"},
				{prop: "area", label: "区/县"},
				{prop: "score_type", label: "计分方式"},
				{prop: "year", label: "考试年份"},
				{prop: "creator", label: "创建人"},
				{prop: "created_at", label: "创建时间"},
				{
					prop: "handle",
					label: "操作",
					width: "80rem",
					handle: true,
					render() {
						return ["编辑"]
					}
				},
			],

			historyDialogShow: false,
			historyList: []
		}
	},
	created() {
		this.$store.commit("setPage", 1);
		this.getProvince()
		this.getData()
	},
	activated() {
		this.getData()
	},
	computed: {
		...mapState(["page"])
	},
	methods: {
		getData() {
			let params = {...this.search, page: this.page};
			this.loading = true;
			this.$_register('/api/recruit/examination-rank/list', {params}).then(res => {
				let data = res.data.data
				this.tableData = data.list
				this.total = data.page.total
			}).finally(() => this.loading = false);
		},

		onSearch(val) {
			this.$store.commit('setPage', 1);
			this.search = val;
			this.getData(val)
		},

		getProvince() {
			this.$_register('/api/recruit/common/area', {params: {address_code: '000000'}}).then(res => {
				this.searchConfig[0].options = res.data.data
			})
		},

		getCity(e) {
			this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
				this.searchConfig[1].options = res.data.data
			})
		},

		getArea(e) {
			this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
				this.searchConfig[2].options = res.data.data
			})
		},

		handleEdit(row) {
			this.$router.push('./edit?id=' + row.id)
		},

		// 当前页改变触发
		handleCurrentChange(val) {
			//当前页赋值给currentPage
			this.$store.commit("setPage", val);
			//拉取数据
			this.getData()
		},

		reset() {
			this.searchConfig[1].options = []
		}

	},
}
</script>

<style lang="scss" scoped>
.role-list {
	padding-top: 24rem;
}

.button {
	//  padding-top: 30rem;
	margin-left: 34rem;
	margin-bottom: 30rem;
	box-sizing: border-box;
	width: 90rem;
	height: 34rem;
	opacity: 1;
	border-radius: 4rem;
}

.table {
	margin-left: 32rem;
}

::v-deep .el-table th.el-table__cell {
	text-align: left;
	background-color: rgba(232, 237, 255, 0.39);
	font-size: 16rem;
	font-weight: bold;
	color: #333333;
	font-family: Microsoft YaHei, serif;
}

::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
	text-align: left;
	font-size: 14rem;
	font-family: Microsoft YaHei, serif;
	font-weight: normal;
	height: 48rem;
	box-sizing: border-box;
	color: #333333;
}

.header {
	position: fixed;
	top: 120rem;
	left: 300rem;
	font-size: 16rem;
	font-family: Microsoft YaHei, serif;
	font-weight: bold;
	line-height: 24rem;
	color: #333333;
	letter-spacing: 3.5rem;
}

.el-button--text {
	margin-right: 40rem;
	font-size: 14rem;
	font-family: Microsoft YaHei, serif;
	font-weight: normal;
	color: #3491fa;
}
</style>
